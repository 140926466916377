import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"

const StyledTileMenu = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  gap: 0.5em;

  @media (max-width: 991.98px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 300px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &::before {
    content: "";
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  .box {
    background-color: #f07e14;
    color: black;
    width: 100%;
    height: 100%;
    min-width: 100%;
    padding: 1em;
    box-sizing: border-box;
    hyphens: auto;
    text-align: center;
    cursor: pointer;
    line-height: 1.5em;
    // font-weight: bold;
    font-size: 1em;
    /* font-size: 1.3vw; */
    font-size: 14px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #ffb147;

      .more,
      .more-secondary {
        transform: scale(1.05);
      }
    }

    &--secondary {
      background-color: #f2c297;
    }

    &--wideleft {
      grid-column: 1/4;
    }

    &--wideright {
      grid-column: 4/-1;
    }

    .top {
      font-weight: bold;
    }

    .center {
      font-weight: bold;
      height: 100%;
      display: grid;
      place-items: center;
    }

    .more {
      padding: 1em 2em;
      border-radius: 0.25em;
      color: black;
      background: linear-gradient(145deg, #d87112, #ff8715);
      box-shadow: 5px 5px 10px #cc6b11, -5px -5px 10px #ff9117;
      transition: transform 0.3s ease-in-out;
    }

    .more-secondary {
      padding: 1em 2em;
      border-radius: 0.25em;
      color: black;
      background: linear-gradient(145deg, #daaf88, #ffd0a2);
      box-shadow: 5px 5px 10px #cea580, -5px -5px 10px #ffdfae;
      transition: transform 0.3s ease-in-out;
    }
  }
`

const TileMenu = () => {
  const data = useStaticQuery(graphql`
    query {
      k1: contentfulCourse(courseId: { eq: "k1" }) {
        courseName
        price
        slug
      }
      k2: contentfulCourse(courseId: { eq: "k2" }) {
        courseName
        price
        slug
      }
      k3: contentfulCourse(courseId: { eq: "k3" }) {
        courseName
        price
        slug
      }
      k4: contentfulCourse(courseId: { eq: "k4" }) {
        courseName
        price
        slug
      }
      k5: contentfulCourse(courseId: { eq: "k5" }) {
        courseName
        price
        slug
      }
      kfm1: contentfulCourse(courseId: { eq: "kfm1" }) {
        courseName
        price
        slug
      }
      kfm2: contentfulCourse(courseId: { eq: "kfm2" }) {
        courseName
        price
        slug
      }
      qm1: contentfulCourse(courseId: { eq: "qm1" }) {
        courseName
        price
        slug
      }
      qm2: contentfulCourse(courseId: { eq: "qm2" }) {
        courseName
        price
        slug
      }
      wk1: contentfulCourse(courseId: { eq: "wk1" }) {
        courseName
        price
        slug
      }
      te1: contentfulCourse(courseId: { eq: "te1" }) {
        courseName
        price
        slug
      }
      sf1: contentfulCourse(courseId: { eq: "sf1" }) {
        courseName
        price
        slug
      }
      i401: contentfulCourse(courseId: { eq: "i40-1" }) {
        courseName
        price
        slug
      }
    }
  `)

  const TileButton = (course, isSecondary) => {
    return (
      <Link
        to={course.course.slug}
        className={`box ${
          isSecondary === true ? "box--secondary" : "box--primary"
        }`}
      >
        <div className="top">{course.course.courseName}</div>
        <div className="more">Mehr erfahren</div>
        <div className="bottom">
          2&nbsp;Tage
          <br />
          {course.course.price} €
        </div>
      </Link>
    )
  }

  return (
    <StyledTileMenu>
      <Link to={"/spritzgiessen"} className="box box--secondary">
        <div className="center">
          Erklärung
          <br />
          Spritzgieß&shy;führerschein®
        </div>
      </Link>
      <TileButton course={data.k1} />
      <TileButton course={data.k2} />
      <TileButton course={data.k3} />
      <TileButton course={data.k4} />
      <TileButton course={data.k5} />
      <a
        href="mailto:h.kraft@spritzgiessfuehrerschein.de?subject=Anfrage%20Inhouseschulungen"
        className="box box--secondary"
      >
        <div className="top">
          Inhouse&shy;schulungen
          <br />
          bei Ihnen in der Firma
        </div>
        <div className="bottom more-secondary">Angebot anfordern</div>
      </a>
      <TileButton course={data.kfm1} />
      <TileButton course={data.kfm2} />
      <TileButton course={data.qm1} />
      <TileButton course={data.qm2} />
      <TileButton course={data.i401} isSecondary={true} />
    </StyledTileMenu>
  )
}

export default TileMenu
