import React from "react"
import styled from "styled-components"
import { Link, graphql, useStaticQuery } from "gatsby"

const StyledNextEvents = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  // grid-auto-rows: 1fr;
  gap: 1rem;

  @media (max-width: 991.98px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &::before {
    content: "";
    width: 0;
    // padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  & > *:first-child {
    // grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  .event {
    margin: 1rem auto;
    color: black;
    display: flex;

    &:hover {
      color: var(--primary);
    }

    &-day {
      background-color: #eee;
      padding: 0.5rem;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }

    &-month {
      font-size: 1.5rem;
      border-bottom: 2px solid var(--primary);
    }

    &-city {
      margin-top: 0.5rem;
    }
  }

  .box {
    background-color: #f07e14;
    color: black;
    width: 100%;
    height: 100%;
    min-width: 100%;
    padding: 1em;
    box-sizing: border-box;
    hyphens: auto;
    text-align: center;
    cursor: pointer;
    line-height: 1.5em;
    // font-weight: bold;
    font-size: 1em;
    /* font-size: 1.3vw; */
    font-size: 14px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #ffb147;
    }

    &--secondary {
      background-color: #f2c297;
    }

    &--wideleft {
      grid-column: 1/4;
    }

    &--wideright {
      grid-column: 4/-1;
    }

    .top {
      font-weight: bold;
    }
  }
`

const NextEvents = () => {
  const data = useStaticQuery(graphql`
    query {
      k1: allContentfulEvent(
        filter: { courseType: { eq: "Spritzgießen Kurs 1" } }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }

      k2: allContentfulEvent(
        filter: { courseType: { eq: "Spritzgießen Kurs 2" } }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      k3: allContentfulEvent(
        filter: { courseType: { eq: "Spritzgießen Kurs 3" } }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      k4: allContentfulEvent(
        filter: { courseType: { eq: "Spritzgießen Kurs 4" } }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      k5: allContentfulEvent(
        filter: { courseType: { eq: "Spritzgießen Kurs 5" } }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      kfm1: allContentfulEvent(
        filter: { courseType: { eq: "Spritzgießen für Kaufleute Kurs 1" } }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      kfm2: allContentfulEvent(
        filter: { courseType: { eq: "Spritzgießen für Kaufleute Kurs 2" } }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      qm1: allContentfulEvent(
        filter: {
          courseType: { eq: "Spritzgießen für QM-/QS-Mitarbeiter Kurs 1" }
        }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      qm2: allContentfulEvent(
        filter: {
          courseType: { eq: "Spritzgießen für QM-/QS-Mitarbeiter Kurs 2" }
        }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      sgf: allContentfulEvent(
        filter: {
          courseType: { eq: "Spritzgießfehler erkennen und vermeiden Kurs 1" }
        }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
      i40: allContentfulEvent(
        filter: {
          courseType: { eq: "Industrie 4.0 im Spritzgießbetrieb Kurs 1" }
        }
        sort: { fields: startDate }
      ) {
        edges {
          node {
            startDate
            endDate
            city
          }
        }
      }
    }
  `)

  const getMonthName = month => {
    switch (month) {
      case 0:
        return "Januar"
      case 1:
        return "Februar"
      case 2:
        return "März"
      case 3:
        return "April"
      case 4:
        return "Mai"
      case 5:
        return "Juni"
      case 6:
        return "Juli"
      case 7:
        return "August"
      case 8:
        return "September"
      case 9:
        return "Oktober"
      case 10:
        return "November"
      case 11:
        return "Dezember"
      default:
        break
    }
  }

  // TODO: Optimize getMonths so that dynamically all future events can be shown
  // TODO: Maybe rename getMonths into getMonth and create a new getMonths that takes care of everything
  /**
   *
   * @param {*} events
   * @param {*} month 0 is this month, 1 is next month etc.
   * @param {*} course Which course to link to
   * @param {*} tpye Which subtype to link to (kaufleute, qualitaetsmanagement)
   * @returns
   */
  const getMonths = (events, month, course, type) => {
    if (month >= 12) return
    if (
      events.filter(node => {
        const nodeDate = new Date(node.node.startDate)
        const now = new Date()

        return (
          nodeDate.getFullYear() === now.getFullYear() &&
          nodeDate.getMonth() === now.getMonth() + month &&
          now.getMonth() + month <= 11
        )
      }).length === 0
    )
      return getMonths(events, month + 1, course, type)

    return (
      <>
        <div className="event-month">
          {getMonthName(new Date().getMonth() + month)}
        </div>
        {events
          .filter(node => {
            const nodeDate = new Date(node.node.startDate)
            const now = new Date()
            now.setDate(now.getDate() - 1)

            return (
              nodeDate.getFullYear() === now.getFullYear() &&
              nodeDate.getMonth() === now.getMonth() + month &&
              now.getMonth() + month <= 11 &&
              now <= nodeDate
            )
          })
          .map((node, index) => {
            const url = course !== 0 ? `/${type}/kurs${course}` : `/${type}`
            return (
              <Link key={index} to={url}>
                <div className="event">
                  <span className="event-day">
                    {new Date(node.node.startDate).toLocaleDateString("de", {
                      day: "2-digit",
                    })}
                    ./
                    {new Date(node.node.endDate).toLocaleDateString("de", {
                      day: "2-digit",
                    })}
                    .
                  </span>
                  <div className="event-city">{node.node.city}</div>
                </div>
              </Link>
            )
          })}
      </>
    )
  }

  return (
    <>
      <h2
        className="text-center text-uppercase"
        style={{ marginBottom: "2rem", marginTop: "5rem" }}
      >
        <strong>Die nächsten Termine</strong>
      </h2>
      <StyledNextEvents>
        <div>
          <h3>Kurs 1</h3>
          {getMonths(data.k1.edges, 0, 1, "spritzgiessen")}
          {/*{getMonths(data.k1.edges, 1, 1, "spritzgiessen")}*/}
          <Link to="/kurs1" className="">
            <div className="top">{data.k1.city}</div>
            <div className="bottom">{data.k1.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>Kurs 2</h3>
          {getMonths(data.k2.edges, 0, 2, "spritzgiessen")}
          <Link to="/kurs1" className="">
            <div className="top">{data.k2.city}</div>
            <div className="bottom">{data.k2.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>Kurs 3</h3>
          {getMonths(data.k3.edges, 0, 3, "spritzgiessen")}
          <Link to="/kurs3" className="">
            <div className="top">{data.k3.city}</div>
            <div className="bottom">{data.k3.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>Kurs 4</h3>
          {getMonths(data.k4.edges, 0, 4, "spritzgiessen")}
          <Link to="/kurs1" className="">
            <div className="top">{data.k4.city}</div>
            <div className="bottom">{data.k4.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>Kurs 5</h3>
          {getMonths(data.k5.edges, 0, 5, "spritzgiessen")}
          <Link to="/kurs5" className="">
            <div className="top">{data.k5.city}</div>
            <div className="bottom">{data.k5.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>Kaufleute Kurs 1</h3>
          {getMonths(data.kfm1.edges, 0, 1, "kaufleute")}
          <Link to="/kaufleute/kurs1" className="">
            <div className="top">{data.kfm1.city}</div>
            <div className="bottom">{data.kfm1.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>Kaufleute Kurs 2</h3>
          {getMonths(data.kfm2.edges, 0, 2, "kaufleute")}
          <Link to="/kaufleute/kurs1" className="">
            <div className="top">{data.kfm2.city}</div>
            <div className="bottom">{data.kfm2.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>QM-/QS Kurs 1</h3>
          {getMonths(data.qm1.edges, 0, 1, "qualitaetsmanagement")}
          <Link to="/kaufleute/kurs1" className="">
            <div className="top">{data.qm1.city}</div>
            <div className="bottom">{data.qm1.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>QM-/QS Kurs 2</h3>
          {getMonths(data.qm2.edges, 0, 2, "qualitaetsmanagement")}
          <Link to="/kaufleute/kurs1" className="">
            <div className="top">{data.qm2.city}</div>
            <div className="bottom">{data.qm2.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>Spritzgießfehler</h3>
          {getMonths(data.sgf.edges, 0, 0, "spritzgiessfehler")}
          <Link to="/spritzgiessfehler" className="">
            <div className="top">{data.sgf.city}</div>
            <div className="bottom">{data.sgf.startDate}</div>
          </Link>
        </div>
        <div>
          <h3>Industrie 4.0</h3>
          {getMonths(data.i40.edges, 0, 0, "industrie40")}
          <Link to="/industrie40" className="">
            <div className="top">{data.i40.city}</div>
            <div className="bottom">{data.i40.startDate}</div>
          </Link>
        </div>
      </StyledNextEvents>
    </>
  )
}

export default NextEvents
