import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const HeroImage = props => {
  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq: "Regensburg (cropped)" }) {
        fluid(maxHeight: 400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.contentfulAsset.fluid}
      objectPosition="50% 20%"
      style={{ maxHeight: "400px", zIndex: -1 }}
    />
  )
}

export default HeroImage
